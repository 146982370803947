body {
  /* padding: 18px; */
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
  background-color: #f4f4f4 !important;
  /* background-color: #fff; */
  color: #212529 !important;
  line-height: 1.4rem !important;
}
/* @media (max-width: 1300px) {
  .App {
    max-width: 100%;
  }
}
@media (min-width: 1301px) { */
  .App {
    /* max-width: 80%; */
  }
/* } */
.App {
  padding: 30px 18px;
}
.App h2 {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.a {
  cursor: pointer;
}
a:hover {
  text-decoration: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8) !important;
}
sup {
  color: #808080;
}
.dotted-underline, .link {
  border-bottom: 1px dotted #1b4e6b !important;
  cursor: pointer;
}
.dotted-underline:hover, .link:hover {
  border-bottom: 1px solid #1b4e6b !important;
}
.negative {
  color: #e41b17;
}
.dark-grey {
  color: rgba(0, 0, 0, 0.8);
}
.emphasis {
  font-style: italic;
}
.orange {
  color: #ff6600;
}
.left {
  float: left;
}
.right {
  float: right;
}
.text-align-right {
  text-align: right !important;
}
.text-align-left {
  text-align: left !important;
}
.vertical-align-middle thead th {
  vertical-align: middle !important;;
}
.clear {
  clear: both;
}
.bold {
  font-weight: 500;
}
.underline {
  text-decoration: underline;;
}
.hidden {
  display: none;
}

.btn, .ant-btn-primary { 
  width: auto;
  background-color: #1b4e6b;
  border-color: #376a86;  
}
.btn:hover, .ant-btn-primary:hover,
.btn:focus, .ant-btn-primary:focus { 
  width: auto;
  background-color: #14374a;
  border-color: #14374a;  
}

.btn.export {
  margin-bottom: 15px;
}
.btn.export i {
  margin-right: 5px;
  vertical-align: 0px;
}
.btn-primary {
  background-color: #fff !important;
  border-color: #eee !important;
  color: #212529 !important;
  padding: 5px 10px !important;
}
.btn-primary.submit {
  background: #29559b !important;
  border-color: #29559b !important;
  color: #fff !important;
}
.filter-checkbox {
  color: #fff;
  line-height: 1.2rem;
  margin-top: 13px;
  padding-left: 0 !important;
}
.filter-checkbox .form-check-label {
  margin-top: -10px;
}
.left-border {
  border-left: 1px solid #475873;
}
.right-border {
  border-right: 1px solid #475873;
}
.left-border.dropdown {
  padding: 5px 0px 5px 10px;
}
.dropdown button {
  text-align: left;
}
.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}
.input-group-text { 
  background: none !important;
  border: none !important;
}
/* flex */
.flex {
  display: flex;
}
.justify-flex-start {
  justify-content: flex-start;
}
.justify-flex-end {
  justify-content: flex-end
}
.justify-flex-center {
  justify-content: center;
}
.justify-flex-space-between {
  justify-content: space-between;
}
.justify-flex-space-around {
  justify-content: space-around;
}
.align-self-center {
  align-self: center;
}
.align-self-flex-start { 
  align-self: flex-start
}
.align-self-flex-end {
  align-self: flex-end;
}
.align-self-auto { 
  align-self: auto;
}
.flex-grow-zero {
  flex-grow: 0;
}
.flex-grow-one { 
  flex-grow: 1;
}
/*layout*/
.ant-layout-sider,
.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: #1b4e6b;
  font-family: Lato;
}
.site-layout {
  margin-left: 0;
}
/* margin left when menu is expanded */
.site-layout.contentWidth {
  margin-left: 300px;
}
/*menu*/
.ant-layout-sider .searchUsername {
  width: 85%;
  display: block;
  margin: 30px auto 20px;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item {
  font-size: 15px;
  height: auto !important;
  margin: 0;
  padding: 8px 0;
  line-height: initial;
  padding-left: 52px !important;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item:hover,
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item-selected {
  background: #3b6e8b;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item a {
  color: #B0CEE6;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item a:hover {
  color: #B0CEE6 !important;
  border-bottom: none !important;
}
/* menu category */
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.header-menu-item {
  color: #fff !important;
  margin-top: 20px;
  font-size: 14px;
  cursor: initial;
  margin-bottom: 5px;
  font-weight: 500;
  padding-left: 24px !important;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.header-menu-item:hover,
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.header-menu-item:active {
  background-color: initial;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.header-menu-item.menu-item {
  font-size: 15px;
  text-transform: capitalize;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item:last-child {
  margin-bottom: 20px;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item .number {
  width: 45px;
  display: inline-block;
  display: none;
}
.ant-layout-sider .expandedSection.hidden { 
  display: none;
}

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  font-size: 18px;
}
.ant-layout-sider .expandedSection .ant-menu .ant-menu-item a:hover {
  color: #fff !important;
}
.ant-layout-sider .expandedSection .ant-menu .ant-menu-item.header-menu-item {
  color: #b7bbe1 !important;
  margin-top: 20px;
  font-size: 15px;
  cursor: initial;
  margin-bottom: 5px;
  font-weight: 500;
  padding-left: 24px !important;
}
.ant-layout-sider .expandedSection .ant-menu .ant-menu-item {
  color: #fff;
}
.ant-layout-sider .expandedSection .ant-menu .ant-menu-item:hover,
.ant-layout-sider .expandedSection .ant-menu .ant-menu-item-selected {
  background-color: #8a90c2;
}
.ant-layout-sider .smaSection .expandedSection .ant-menu .ant-menu-item:hover,
.ant-layout-sider .smaSection .expandedSection .ant-menu .ant-menu-item-selected {
  background-color: #74a77c;
}
.ant-layout-sider .maSection .expandedSection .ant-menu .ant-menu-item:hover,
.ant-layout-sider .maSection .expandedSection .ant-menu .ant-menu-item-selected { 
  background-color: #61B0BA;
  color: #fff;
}
.ant-layout-sider .maSection .expandedSection .ant-menu .ant-menu-item,
.ant-layout-sider .maSection .expandedSection .ant-menu .ant-menu-item a,
.ant-layout-sider .maSection .expandedSection .ant-menu .subSection .ant-menu-submenu-title{
  color: #003d60;
}
.ant-layout-sider .maSection .expandedSection .ant-menu .subSection .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-layout-sider .maSection .expandedSection .ant-menu .subSection .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #003d60;
}
.ant-layout-sider .agtSection .expandedSection .ant-menu .ant-menu-item,
.ant-layout-sider .agtSection .expandedSection .ant-menu .ant-menu-item a,
.ant-layout-sider .agtSection .expandedSection .ant-menu .ant-menu-item a:hover,
.ant-layout-sider .agtSection .expandedSection .ant-menu .subSection .ant-menu-submenu-title,
.ant-layout-sider .agtSection .expandedSection .ant-menu .subSection .ant-menu-submenu-title i {
  color: #6b4300 !important;
}
.ant-layout-sider .agtSection .expandedSection .ant-menu .subSection .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-layout-sider .agtSection .expandedSection .ant-menu .subSection .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #6b4300;
}
.ant-layout-sider .agtSection .expandedSection .ant-menu .ant-menu-item:hover,
.ant-layout-sider .agtSection .expandedSection .ant-menu .ant-menu-item-selected { 
  background-color: #e09d2e;
}

.ant-layout-sider .expandedSection .ant-menu .ant-menu-item a {
  color: #fff;
}
.ant-layout-sider .expandedSection .ant-menu .ant-menu-item.header-menu-item:hover,
.ant-layout-sider .expandedSection .ant-menu .ant-menu-item.header-menu-item:active {
  background-color: initial;
}
.ant-layout-sider .userSection .userInfo .userParticulars .loginName,
.ant-layout-sider .userSection .userInfo .userParticulars .userCredit {
  color: #b7bbe1 !important;
}
.ant-layout-sider .smaSection .expandedSection .ant-menu .ant-menu-item.header-menu-item,
.ant-layout-sider .smaSection .userInfo .userParticulars .loginName, 
.ant-layout-sider .smaSection .userInfo .userParticulars .userCredit { 
  color: #9fcaa6 !important;
} 
.ant-layout-sider .maSection .expandedSection .ant-menu .ant-menu-item.header-menu-item,
.ant-layout-sider .maSection .userInfo .userParticulars .loginName, 
.ant-layout-sider .maSection .userInfo .userParticulars .userCredit { 
  color: #375068 !important;
}
.ant-layout-sider .agtSection .expandedSection .ant-menu .ant-menu-item.header-menu-item,
.ant-layout-sider .agtSection .userInfo .userParticulars .loginName, 
.ant-layout-sider .agtSection .userInfo .userParticulars .userCredit { 
  color: #63502f !important;
}
.ant-layout-sider .expandedSection .ant-menu .subSection {
  margin-top: 20px;
}
.ant-layout-sider .expandedSection .ant-menu .subSection .ant-menu-submenu-title {
  color: #fff;
  font-size: 15px;
  height: auto !important;
  margin: 0;
  padding: 8px 0;
  line-height: initial;
}
.ant-layout-sider .expandedSection .ant-menu .ant-menu-inline.ant-menu-sub {
  box-shadow: unset;
}
/*menu userinformation */
.ant-layout-sider .userSection {
  background: #5c63a2;
  color: #e4dfff;
  position: relative;
  padding-bottom: 15px;
}
.ant-layout-sider .userSection.smaSection {
  color: #d1f2d6;
}
.ant-layout-sider .userSection.maSection {
  color: #003d60;
}
.ant-layout-sider .userSection.agtSection {
  color: #000;
}

.ant-layout-sider .expandedSection .ant-menu {
  background: #5C63A2;
}
.ant-layout-sider .userSection.smaSection,
.ant-layout-sider .smaSection .expandedSection .ant-menu {
  background: #3d8349;
}
.ant-layout-sider .userSection.maSection,
.ant-layout-sider .maSection .expandedSection .ant-menu {
  background: #94c9d0;
}
.ant-layout-sider .userSection.agtSection,
.ant-layout-sider .agtSection .expandedSection .ant-menu {
  background: #F4AB33;
}
.ant-layout-sider .userSection .userInfo {
  padding: 20px;
  display: flex;
}
.ant-layout-sider .userSection .userInfo .userLogo {
  width: 83px;
  cursor: pointer;
}
.ant-layout-sider .userSection .userInfo .userParticulars {
  margin: 7px 20px 0;
}
.ant-layout-sider .userSection .userInfo .userParticulars .userName {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 7px;
}
.ant-layout-sider .userSection .icons { 
  padding: 5px 20px 0px;
}
.ant-layout-sider .userSection .icons i {
  cursor: pointer;
  color: #fff;
}
.ant-layout-sider .smaSection .icons i {
  color: #fff;
}
.ant-layout-sider .maSection .icons i {
  color: #114969;
}
.ant-layout-sider .agtSection .icons i {
  color: #6b4300;
}
.ant-layout-sider .userSection .icons .riskyPlayersIcon {
  padding: 0 5px 0 0;
  font-size: 26px;
  vertical-align: 0;
}
.riskyBadge .ant-badge-count {
  top: 0px;
  right: 6px;
}
.ant-layout-sider .userSection .icons .mailIcon {
  padding: 0px 18px 0;
  font-size: 26px;
  vertical-align: 0;
}
.ant-layout-sider .userSection .icons .notificationsIcon {
  padding: 0 5px;
  font-size: 26px;
  vertical-align: 0;
}
.ant-layout-sider .notificationsBadge .ant-badge-count {
  top: -1px;
}
.ant-layout-sider .ant-badge-count {
  min-width: 15px;
  box-shadow: unset;
  line-height: 17px;
}

.ant-layout-sider .expandIconBg:after {
  content: '';
  position: absolute;
  left: calc(50% - 18px);
  bottom: -27px;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-top: 15px solid #5c63a2;
}
.ant-layout-sider .smaSection .expandIconBg:after {
  border-top: 15px solid #3d8349;
}
.ant-layout-sider .maSection .expandIconBg:after {
  border-top: 15px solid #94c9d0;
}
.ant-layout-sider .agtSection .expandIconBg:after {
  border-top: 15px solid #F4AB33;
}
.ant-layout-sider .expandIcon { 
  position: absolute;
  left: calc(50% - 15px);
  bottom: -13px;
  cursor: pointer;
  color: #313873;
  font-size: 18px;
}
.ant-layout-sider .collapseIcon { 
  position: absolute;
  left: calc(50% - 15px);
  cursor: pointer;
  bottom: 2px;
  color: #313873;
  font-size: 18px;
}
.ant-layout-sider .smaSection .expandIcon, 
.ant-layout-sider .smaSection .collapseIcon { 
  color : #14561f;
}
.ant-layout-sider .maSection .expandIcon, 
.ant-layout-sider .maSection .collapseIcon { 
  color: #114969;
}
.ant-layout-sider .agtSection .expandIcon, 
.ant-layout-sider .agtSection .collapseIcon { 
  color: #6b4300;
}
/* content header */
.ant-layout-header {
  line-height: 17px;
  background: #dbdbdb;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.ant-layout-header i {
  color: #90979e;
  font-size: 18px;
  cursor: pointer;
}
.ant-layout-header i.trigger { 
  padding: 10px 15px;
}
.ant-layout-header .rightSection { 
  display: flex;
}
.ant-layout-header .date {
  color: #666666;
  padding: 10px 20px;
}
.ant-layout-header i.icon {
  padding: 10px 15px;
  border-left: 1px solid #fff;
}

/* content */
.breadcrumbs {
  margin-bottom: 15px;
}
.anticon {
  vertical-align: 0.125em;
}
.ant-breadcrumb {
  margin-top: 5px;
}
.ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.8);
}
table.table {
  border-collapse: collapse;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 0px 5px 2px #c5c5c5;
}
table.table a {
  color: #212529 !important;
}
table.table tr td.bg-red {
  background: #f5c6cb;
}
.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 1px !important;
}
table.table .filter-bar { 
  background: #1b4e6b;
}
table.table .filter-bar .filter-bar-row .col {
  flex-grow: 0;
}
table.table .filter-bar .filter-bar-row .daterange-to {
  color: #fff;
}
table.table .filter-bar td { 
  border: 1px solid #1b4e6b;
}
table.table .filter-bar .btn {
  width: 100%;
}
table.table.without-header .filter-bar {
  display: none;
}
table.table tr.light-blue { 
  background-color: #d5dae5 !important;
  text-align: left;
  color: #475873;
}
table.table tr.alt-blue { 
  text-align: left;
  background-color: #7c9eb1;
  font-weight: 500;
  color: #fff;
}
table.table tr.alt-blue span {
  padding-left: 30px;
}
table.table tr.alt-blue td { 
  padding: 2px 10px;
}
table.table tr.light-blue th {
  padding: 5px 10px;
}
table.table tr.light-blue td { 
  padding: 5px 10px;
}
.table thead th {
  background-color: #3b6e8b;
  color: #fff;
  border-color: #475873;
  font-size: 16px;
  font-weight: normal;
  padding: 8px 10px;
  border-bottom: 1px solid #475873 !important;
}
.table tbody td {
  font-size: 14px;
  padding: 7px 10px;
}
.table tbody td.bold {
  font-weight: bold;
}
.table tbody tr.disabled {
  background-color: #d8d8d8 !important;
}
table.vertical-align-middle-table tbody tr td {
  vertical-align: middle;
  padding: 2px 0;
}
.transferButton { 
  display: block;
  margin: 0 auto 20px auto;
}
table.table.dashboardBalance {
  font-weight: 500;
  box-shadow: unset;
  border: 1px solid #dee2e6;
}
table.table.dashboardBalance tbody tr td {
  width: 50%;
}
/* ant table overwite */
.accountList {
  box-shadow: 0px 0px 4px 2px #c5c5c5;
}
.ant-table-bordered .ant-table-header > table, .ant-table-bordered .ant-table-body > table, .ant-table-bordered .ant-table-fixed-left table, .ant-table-bordered .ant-table-fixed-right table {
  border: none;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: initial;
}
div.filter-bar {
  background: #1b4e6b;
  padding: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
div.filter-bar .btn {
  width: 100%;
}
.ant-table-wrapper.table {
  background: #fff;
}
.ant-table-wrapper.table tr td {
  text-align: center;
  vertical-align: middle;
}
.ant-table-wrapper.table tr td .edit {
  cursor: pointer;
  border-bottom: 1px dotted;
}
.ant-table-wrapper.table tr td .edit:hover {
  border-bottom: 1px solid;
}
.ant-table-bordered .ant-table-thead > tr > th {
  border-right: 1px solid #475873;
  text-align: center;
}
.ant-table-bordered tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}
/* ssma site */
.ssmaContainer { 
  max-height: 800px;
}
.ssma .search { 
  height: 38px;
}
.ssma .search .ant-input {
  color: #C3C7CB;
  border-color: #C3C7CB;
}
table.table.ssma .filter-bar { 
  background: #304566;
}
.table.ssma thead th {
  background-color: #516584;
}
.table.ssma tr.light-blue {
  background-color: #cfd7e6 !important;
  border-color: #dee2e6 !important;
  text-align: left;
}
.table.ssma tr.light-blue th {
  padding: 7px 10px;
}
.table.ssma tr.total {
  font-weight: bold;
}
.table.ssma tr td span.positive { 
  color: #007bff;
}
.table.ssma .selectFilterSearch .ant-select-selection--single {
  height: 36px;
}
.table.ssma .selectFilterSearch .ant-select-selection__rendered {
  line-height: 34px;
}
.table.ssma .selectFilterSearch .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
  color: #212529;
}
.table.ssma .selectFilterSearch .ant-select-arrow {
  color: #212529;
}
.ant-select-dropdown .ant-select-dropdown-menu-item {
  color: #212529 !important;
}

/* dashboard winning / losing players */
.ant-tabs-bar {
  margin: 0;
  border-color: #dadada;
}
.ant-tabs .ant-tabs-content {
  padding: 20px;
  background-color: #fff;
}
.ant-tabs .ant-tabs-content table {
  margin-bottom: 0;
  box-shadow: unset;
  border: 1px solid #dee2e6;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  /* border-bottom: unset; */
  color: #3B6E8B;
}
.ant-tabs-nav {
  font-size: 15px;
}
.ant-tabs-nav .ant-tabs-tab .anticon {
  font-size: 18px;
  vertical-align: 0.05em;
  margin-right: 5px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding: 0 20px;
  border: none;
}

/* dashboard balance information */
.dashboard {

}
.dashboard .datepicker { 
  margin-bottom: 15px;
}
.dashboard .customAlert {
  background: #d5dae5;
  padding: 10px 15px;
  margin-bottom: 20px;
  border: 1px solid #b5bed2;
  border-radius: 5px;
  color: #313873;
  position: relative;
}
.dashboard .customAlert a {
  border-bottom: 1px solid;
  color: #313873 !important;
}
.dashboard .customAlert i {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #787d82;
  cursor: pointer;
}
.dashboard h2 {
  font-size: 20px;
  color: #1b4e6b;
}
.title-searchbar {
  margin-top: 2px;
}
.dashboardSection {
  padding: 20px;
  background-color: #fff;
  margin-top: 20px;
}
.dashboardSection h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #3b6e8b;
}
.dashboardSection h3 i {
  margin-right: 10px;
  font-size: 20px;
  vertical-align: 0em;
}
/* create account */
.createAccount {
  padding: 25px;  
}
.createAccount .copySettings { 
  margin-bottom: 15px;
  box-shadow: 6px 0px 5px 2px #c5c5c5 !important;
}
.createAccount .userSettings { 
  box-shadow: 6px 0px 5px 2px #c5c5c5 !important;
}
.createAccount .ant-btn-primary { 
  margin: 3px 0;
  width: 100%;
}
.createAccount .ant-row.header {
  background: #3b6e8b;
  color: #fff;
  text-align: center;
  padding: 7px;
}
.createAccount .ant-form-item, 
.createAccount .ant-form-item label {
  margin-bottom: 0;
}
.createAccount .content { 
  background: #fff;
}
.createAccount .content .contentPadding {
  padding: 10px 0;
}
.createAccount .content.innerSection {
  border-bottom: 1px solid #eee;
}
.createAccount .content .border-right { 
  border-right: 1px solid #eee;
}
.createAccount .content .accountIDPadding { 
  padding: 4px 0;
}
.createAccount .content .recaptchaCol label {
  line-height: 3.4rem;
}
.createAccount .content .suptext {
  color: #808080;
  font-size: 12px;
  padding: 10px 0;
}
.createAccount .content span.suptext {
  padding: 10px;
}
.createAccount .content .createBtn { 
  width: 200px;
  display: block;
  margin: 10px auto;
}

/* credit */
.creditPage {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  padding-bottom: 0;
}
.creditDrawer {
  height: 70px !important;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px #c5c5c5 !important;
  padding: 20px;
  text-align: center;
}
.creditDrawer .viewSelected .ant-checkbox-wrapper {
  margin-right: 10px;
}
.creditDrawer .ant-btn-primary {
  margin-left: 30px;
  width: 180px;
}
.creditModal .headerCol {
  padding: 15px 10px;
  background: #1b4e6b;
  color: #fff;
  text-align: right;
  border: 1px solid #1b4e6b;
  border-top: 1px solid #083650;
}
.creditModal .modal-title {
  font-size: 20px;
}
.creditModal .ant-row:first-child .headerCol {
  border-top: 1px solid #1b4e6b;
}
.creditModal .contentCol {
  padding: 10px;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.creditModal .ant-row:last-child .contentCol {
  border-bottom: 1px solid #dee2e6;
}